<template>
  <b-card>
    <!-- media -->
     <!-- <b-media no-body class="pb-2">
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  height="80"
                  width="80"
                  :src="imageUrl"
                />
              </b-link>
            </b-media-aside>
            <b-media-body class="mt-75 ml-75">
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
              >
                {{ $t('change') }}
              </b-button>
              <b-form-file
                v-model="image"
                ref="refInputEl"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
              />
            </b-media-body>
          </b-media> -->
    <!--/ media -->

    <!-- form -->
    <!-- <g-form class="mt-2"> -->
      <b-row>
        <b-col sm="4">
        <label for="login-password">{{ $t('userName') }}</label>
          <g-field
              :value.sync="selectedItem.name"
              placeholder=""
              :label="$t('userName')"
              name="name"
            >
            </g-field>
        </b-col>
        <b-col sm="4">
        <label for="login-password">{{ $t('email') }}</label>
          <g-field
              :value.sync="selectedItem.email"
              placeholder=""
              :label="$t('email')"
              name="email"
            >
            </g-field>
        </b-col>
        <b-col sm="4">
        <label for="login-password">{{ $t('contactPerson') }}</label>
          <g-field
              :value.sync="selectedItem.contactPerson"
              placeholder=""
              :label="$t('contactPerson')"
              name="contactPerson"
            >
            </g-field>
        </b-col>
        <b-col sm="4">
        <label for="login-password">{{ $t('contactPhone') }}</label>
          <g-field
              :value.sync="selectedItem.contactPhone"
              placeholder=""
              :label="$t('contactPhone')"
              name="contactPhone"
            >
            </g-field>
        </b-col>

        <b-col cols="12">
          <!-- <b-button
            variant="primary"
            class="mt-2 mr-1"
          >
            {{ $t('save') }}
          </b-button> -->
        </b-col>
      </b-row>
    <!-- </g-form> -->
    <hr>
    <b-list-group>
    <b-list-group-item
      class="flex-column align-items-start"
      v-for="item in selectedItem.tenants"
        :key="item.id"
    >
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">
          {{ item.name }}
        </h5>
        <small class="text-primary">{{ getDate(item.registrationDateUtc) }}</small>
      </div>
      <b-card-text class="mb-1">
        <p><span class="text-primary">{{ $t('domin') }}</span> : <strong>{{ item.domainName }}</strong></p>
        <p><span class="text-primary">{{ $t('commercialRegisterNumber') }}</span> : <strong>{{ item.commercialRegisterNumber }}</strong></p>
        <p><span class="text-primary">{{ $t('taxNumber') }}</span> : <strong>{{ item.taxNumber }}</strong></p>
        <p><span class="text-primary">{{ $t('address') }}</span> : <strong>{{ item.address }}</strong></p>
      </b-card-text>
    </b-list-group-item>
  </b-list-group>
  </b-card>
</template>

<script>
import { BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      selectedItem: {},
      image: null,
      imageUrl: 'no_image.png',
      profileFile: null,
      base64Image: '',
      userData: JSON.parse(localStorage.getItem('@USER_PROFILE')),
    }
  },
  mounted() {
    //   this.imageUrl = this.selectedItem.imageUrl
    //       ? `${this.baseUrl}${this.selectedItem.imageUrl}`
    //       : 'no_image.png';
    this.imageUrl = 'no_image.png';
    this.getData();
  },
  methods: {
    getData() {
      this.get({ url: `Clients/${this.userData.clientId}` }).then((data) => {
        this.selectedItem = data;
      });
    }
  },
}
</script>
